import type { NextPage } from 'next';
// Components
import Shell from 'components/appShell';
import Header from 'components/jobs-and-projects/header';
import WorkOrdersTable from 'components/tables/workOrders';
import RouteGuard from 'common/components/RouteGuard/RouteGuard';

const Jobs: NextPage = () => {
  return (
    <RouteGuard>
      <Shell>
        <Header />
        <WorkOrdersTable
          showPrePostCompletionFilters
          filters={{}}
          enableHistoryState
        />
      </Shell>
    </RouteGuard>
  );
};

export default Jobs;
