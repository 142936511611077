import JobsAndProjectsFileIcon from 'styleguide/src/components/icons/JobsAndProjectsFile';
import {
  HeaderBlock,
  HeaderBlockIcon,
  HeaderBlocks,
  HeaderTitle,
  HeaderBlockInfo,
  HeaderBlockInfoTitle,
  HeaderBlockInfoDescription,
  HeaderBlockInfoLink,
  HeaderStyled,
} from './Header.styles';

interface HeaderItemProps {
  title: string;
  description: string;
  href: string;
  variant?: 'project' | 'job' | 'salesforce';
}

const HeaderItem = ({ title, description, href, variant }: HeaderItemProps) => {
  return (
    <HeaderBlock href={href}>
      <HeaderBlockIcon variant={variant}>
        <JobsAndProjectsFileIcon />
      </HeaderBlockIcon>
      <HeaderBlockInfo>
        <HeaderBlockInfoTitle>{title}</HeaderBlockInfoTitle>
        <HeaderBlockInfoDescription>{description}</HeaderBlockInfoDescription>
        <HeaderBlockInfoLink>Create</HeaderBlockInfoLink>
      </HeaderBlockInfo>
    </HeaderBlock>
  );
};

const Header = () => {
  return (
    <HeaderStyled>
      <HeaderTitle>{'Project & Jobs'}</HeaderTitle>
      <HeaderBlocks>
        <HeaderItem
          title="New Project"
          description="Initial Assessment + Work Orders"
          variant="project"
          href="/jobs-and-projects/projects/create"
        />
        <HeaderItem
          title="New Job"
          description="Any type of work order"
          variant="job"
          href="/jobs-and-projects/jobs/create"
        />
        <HeaderItem
          title="Import Job"
          description="Import Job from Salesforce"
          variant="salesforce"
          href="/jobs-and-projects/jobs/import"
        />
      </HeaderBlocks>
    </HeaderStyled>
  );
};

export default Header;
