import { useEffect } from 'react';
import { useGetCompanyByDomainQuery } from 'common/services/endpoints/publicCompany';
import usePersistentQueryParams, {
  PersistentQueryParams,
} from 'common/hooks/usePersistentQueryParams';
import { useRouter } from 'next/router';

/**
 * Returns company branding data based on the domain or query params.
 * Current domain takes priority over query params.
 */
const useCompanyBrandingCustomDomain = () => {
  const router = useRouter();
  const {
    isReady,
    data: { brandedCompanyData },
  } = usePersistentQueryParams([
    { key: PersistentQueryParams.BRANDED_COMPANY_DATA },
  ]);

  const domain = window.location.origin;

  const {
    data: domainCompany,
    isLoading: isDomainCompanyLoading,
    error: domainCompanyError,
  } = useGetCompanyByDomainQuery({ domain });

  useEffect(() => {
    /**
     * Check to see if there is a branded company with a domain in local storage.
     * If there is and it does not match the current domain, redirect to the proper
     * domain
     */
    if (
      isReady &&
      brandedCompanyData?.customDomain &&
      brandedCompanyData.customDomain !== domain
    ) {
      router.push(brandedCompanyData.customDomain);
    }
  }, [brandedCompanyData?.customDomain, domain, isReady, router]);

  return {
    domainCompany,
    isDomainCompanyLoading,
    domainCompanyError,
  };
};

export default useCompanyBrandingCustomDomain;
