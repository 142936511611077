import styled, { css } from 'styled-components';
import Color from 'color';
import Link from 'styleguide/src/components/Link';
import {
  Heading6,
  BodySmall,
  CTASmall,
} from 'styleguide/src/components/Typography/Typography.styles';

interface HeaderBlockIcon {
  variant?: 'project' | 'job' | 'salesforce';
}

export const HeaderStyled = styled.div`
  margin-bottom: 64px;
`;

export const HeaderTitle = styled.div`
  color: ${(props) => props.theme.colors.black};
  ${BodySmall};
  margin-bottom: 26px;
`;

export const HeaderBlocks = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.large} {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    column-gap: 20px;
  }
`;

export const HeaderBlock = styled(Link)`
  border: 1px solid
    ${(props) => Color(props.theme.colors.black).alpha(0.1).toString()};
  border-radius: 6px;
  padding: 10px;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.breakpoints.large} {
    margin-bottom: 20px;
  }
`;

export const HeaderBlockIcon = styled.div<HeaderBlockIcon>`
  ${({ theme, variant = 'project' }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${variant === 'project' &&
    css`
      background-color: ${Color(theme.colors.machineGreen600)
        .alpha(0.1)
        .toString()};
    `};
    ${variant === 'job' &&
    css`
      background-color: rgba(255, 170, 0, 0.1);
    `};
    ${variant === 'salesforce' &&
    css`
      background-color: #fff0fb;
    `};
    border-radius: 4px;
  `}
`;

export const HeaderBlockInfo = styled.div`
  margin-left: 10px;
`;

export const HeaderBlockInfoTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    ${Heading6};
    font-weight: 600;
    margin-bottom: 7px;
  `}
`;

export const HeaderBlockInfoDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray400};
    font-family: ${theme.fontFamily.body};
    ${CTASmall};
    margin-bottom: 8px;
  `}
`;

export const HeaderBlockInfoLink = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.machineGreen600};
    ${CTASmall};
    font-weight: 600;
    margin-bottom: 8px;
    text-decoration: none;
  `}
`;
