import { api } from 'common/store/api';
import { Company } from 'common/types/Company';

const PUBLIC_COMPANY = '/public/companies';

const apiWithTag = api.enhanceEndpoints({
  addTagTypes: ['Company', 'Companies', 'Properties', 'VendorCompliance'],
});

const companyEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyByParams: builder.query<
      Company,
      { companyId: number; companyTitle: string }
    >({
      query: ({ companyId, companyTitle }) => ({
        url: `${PUBLIC_COMPANY}/${companyId}/${companyTitle}`,
      }),
      providesTags: ['Company'],
    }),
    getCompanyByDomain: builder.query<Company, { domain: string }>({
      query: ({ domain }) => ({
        url: `${PUBLIC_COMPANY}/domain`,
        params: {
          domain,
        },
      }),
      providesTags: ['Company'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetCompanyByParamsQuery, useGetCompanyByDomainQuery } =
  companyEndpoints;
