import {
  getItemWithExpiry,
  setItemWithExpiry,
} from 'common/utils/localStorage';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

interface BrandedCompanyData {
  companyId?: string;
  title: string;
  customDomain?: string;
}

export interface OpportunityApplicationData {
  companyId: number;
  opportunityId: number;
}

export enum PersistentQueryParams {
  BRANDED_COMPANY_DATA = 'brandedCompanyData',
  OPPORTUNITY_APPLICATION_DATA = 'opportunityApplicationData',
}

type StoredQueryParamsData = {
  [PersistentQueryParams.BRANDED_COMPANY_DATA]?: BrandedCompanyData;
  [PersistentQueryParams.OPPORTUNITY_APPLICATION_DATA]?: OpportunityApplicationData;
};

/**
 * Sets/Gets query params from localStorage
 *
 * @param params - an array of objects with the following properties:
 *  - key: the query param key
 *  - expiry: the expiration time in ms
 */
const usePersistentQueryParams = (
  params: { key: PersistentQueryParams; expiry?: number }[],
): { isReady: boolean; data: StoredQueryParamsData } => {
  const { isReady, query } = useRouter();

  const data = useMemo(() => {
    const initialValue = {} as StoredQueryParamsData;
    if (!isReady) {
      return initialValue;
    }

    params.forEach(({ key, expiry }) => {
      const value = query[key];

      if (value) {
        setItemWithExpiry(key, value, expiry);
      }
    });

    const storedData = params.reduce<StoredQueryParamsData>((acc, { key }) => {
      const value =
        getItemWithExpiry<StoredQueryParamsData[keyof StoredQueryParamsData]>(
          key,
        );
      return { ...acc, ...{ [key]: value } };
    }, initialValue);

    return storedData;
  }, [isReady, params, query]);

  return { isReady, data };
};

export default usePersistentQueryParams;
