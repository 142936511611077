import useRoles from 'common/hooks/useRoles';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';
import Loader from 'styleguide/src/components/Loader';
import Warning from 'styleguide/src/components/Warning';
import { AccountRole } from 'common/types/AccountRole';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  PRE_REDIRECTED_KEY,
  PRE_REDIRECT_ROUTE_KEY,
  PRE_REDIRECT_ROUTE_ROLE_KEY,
} from 'common/constants';
import { setItem, removeItem, getItem } from 'common/utils/localStorage';
import useCompanyBrandingCustomDomain from 'common/hooks/useCompanyBrandingCustomDomain';

interface Props {
  allowedRoles?: AccountRole[];
}

function RouteGuard({ children, allowedRoles }: PropsWithChildren<Props>) {
  const { isLoading, isAuthenticated, role, error } = useRoles();
  const router = useRouter();
  useCompanyBrandingCustomDomain();
  const isFetchError = error
    ? (error as FetchBaseQueryError)?.status === 'FETCH_ERROR'
    : false;

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !isFetchError) {
      setItem(PRE_REDIRECT_ROUTE_KEY, router.asPath);
      router.replace('/login');
    } else if (isAuthenticated && !isLoading && !isFetchError) {
      // Check if there is saved pre-redirect data
      const preRedirectRoute = getItem(PRE_REDIRECT_ROUTE_KEY);
      const preRedirectRouteRole = getItem(PRE_REDIRECT_ROUTE_ROLE_KEY);
      const preRedirected = Boolean(getItem(PRE_REDIRECTED_KEY));

      // If user has not already been redirected
      if (!preRedirected) {
        if (preRedirectRoute) {
          // Set redirect flag to true
          setItem(PRE_REDIRECTED_KEY, true);

          if (preRedirectRouteRole) {
            if (role === preRedirectRouteRole) {
              router.push(preRedirectRoute);
            }
          } else {
            router.push(preRedirectRoute);
          }
        }
      } else {
        // Remove pre-redirect data if exists
        removeItem(PRE_REDIRECTED_KEY);
        removeItem(PRE_REDIRECT_ROUTE_KEY);
        removeItem(PRE_REDIRECT_ROUTE_ROLE_KEY);

        // If current role does not match allowed roles after initial redirect, send to index
        if (role && !allowedRoles?.includes(role)) {
          router.push('/');
        }
      }
    }
  }, [isLoading, isAuthenticated, router, isFetchError, role, allowedRoles]);

  const onErrorResolve = () => {
    window.location.reload();
  };

  if (isFetchError) {
    return (
      <Warning
        inline
        onResolve={onErrorResolve}
        description="Unable to communicate with server. Please try again."
      />
    );
  }

  if (isLoading || !isAuthenticated) {
    return <Loader fullscreen />;
  }

  if (!role || (allowedRoles && !allowedRoles.includes(role))) {
    return (
      <Warning
        description="You don't have permission to access this route."
        inline
        onResolve={() => router.push('/')}
      />
    );
  }

  return <>{children}</>;
}

export default RouteGuard;
